<template>
    <v-content>
        <v-breadcrumbs :items="breadCrumb" large></v-breadcrumbs>

        <!--<v-snackbar v-model="snackbar" color="success" :timeout="callbackResponse.timeout" top>
            {{callbackResponse.message}}
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>-->

        <v-dialog v-model="dialogEdit" max-width="500px">
            <editForm v-if="dialogEdit"
                      :editData="editedDataPackage"
                      :success="success"
                      @formResponse="onResponse" />
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <deleteListData :deleteFormData="deleteFormData" @formResponse="onResponse"></deleteListData>
        </v-dialog>
        <v-container fluid>
            <v-expansion-panels hover>
                <v-expansion-panel>
                    <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row align="center" class="mt-3">
                            <v-col class="float-right" cols="12" sm="6">
                                <v-menu :close-on-content-click="false"
                                        :nudge-right="40"
                                        class="col-sm-6 col-md-4"
                                        min-width="290px"
                                        offset-y
                                        transition="scale-transition"
                                        v-model="snackbar.menu">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field clearable
                                                      :label="$t('select_date')"
                                                      prepend-inner-icon="event"
                                                      readonly
                                                      dense
                                                      outlined
                                                      v-model="serverParams.date"
                                                      v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="serverParams.date"></v-date-picker>

                                </v-menu>
                            </v-col>
                            <v-col class="float-right" cols="12" sm="6">
                                <v-autocomplete v-model="serverParams.reportTypeID"
                                                :items="monthTypes"
                                                clearable
                                                item-text="value"
                                                item-value="id"
                                                :label="$t('report_type')">
                                </v-autocomplete>
                            </v-col>

                        </v-row>
                        <v-row align="center" justify="center">
                            <v-btn color="primary" dark v-on:click="searchInput" class="mr-1">
                                <i class="material-icons">search</i>{{$t('search')}}
                            </v-btn>
                            <v-btn color="error" dark @click="clearInput">
                                <i class="material-icons">clear</i>{{$t('clear')}}
                            </v-btn>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-col cols="12" sm="12">
                <v-card class="pa-5" elevation="5">
                    <h1 class="headline mb-2 primary--text"
                        style="border-bottom: 1px solid grey">
                        {{$t('occupied_seats_in_airlines')}}
                    </h1>
                    <template>
                        <div id="app">
                            <GChart type="ColumnChart"
                                    :data="totalTouristVisitBar"
                                    :options="chartOptions"
                                    style="height: 500px" />
                        </div>
                    </template>
                </v-card>
            </v-col>


            <!--<vue-good-table mode="remote"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    :totalRows="totalRecords"
                    :isLoading.sync="isLoading"
                    :pagination-options="{ enabled: true,  perPageDropdown: [20, 50, 100,200]}"
                    :rows="roomRows"
                    :columns="columnsRoom">
        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
                <v-chip class="ma-2"
                        color="green"
                        text-color="white"
                        @click="editItem(props.row)">Details</v-chip>
            </span>
        </template>-->
            <!--<template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
                <router-link v-for="room in roomRows" :key="room" :to="{name: 'Details', params:{hotelID: room.branchID}}">
                    <v-chip class="ma-2"
                            color="green"
                            text-color="white">Details</v-chip>
                </router-link>
            </span>
        </template>-->
            <!--</vue-good-table>-->
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";
    import deleteListData from "@/components/deleteModal";
    import { parseJwt } from "@/mixins/parseJwt";

    export default {
        name: "package_create_form",
        mixins: [parseJwt],
        watch: {
            "serverParams.reportTypeID": {
                handler: function (val) {
                    if (val) {
                        this.loadItemsAirline();
                    }
                },
            },
            dialog(val) {
                val || this.close();
            },
            success: {
                handler: function (val, oldVal) {
                    console.log(val, oldVal);
                    this.loadItems();
                },
                deep: true
            }
        },
        components: {
            deleteListData
        },
        computed: {
            breadCrumb() {
                return [
                    {
                        text: this.$t('reports'),
                        to: "/CaanReportPage",
                        disabled: false,
                        exact: true
                    },
                    {
                        text: this.$t('airline'),
                        disabled: true
                    }
                ]
            }
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000
                },
                snackbar: {
                    snackbar: false,
                    menu:false,
                },
                success: false,
                totalTouristVisitBar:[],
                dialogCreate: false,
                dialogEdit: false,
                dialogDelete: false,
                panel: [0],
                deleteFormData: {},
                popout: true,
                isLoading: false,
                roomRows: [],
                chartOptions: {
                    chart: {
                        title: "Company Performance",
                        subtitle: "Sales, Expenses, and Profit: 2014-2020",
                    },
                },
                totalRecords: 0,
                monthTypes: [
                    { id: "1", value: "Yearly" },
                    { id: "2", value: "Monthly" },
                    { id: "3", value: "Daily" }
                ],
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "BranchName",
                            type: "asc"
                        }
                    ],
                    page: 1,
                    perPage: 20
                },
                user: [],
                editedIndex: -1,
                editedDataRoom: {}
            };
        },

        mounted() {
            this.loadItems();
            this.loadItemsAirline();
            this.filterData();
        },
       
        destroyed() {
            console.log(this); // There's practically nothing here!
        },
        methods: {
            editItem(props) {
                window.location.href = '../AirportDetailDashboard/' + props.branchID;
            },

            clearInput() {
                this.serverParams.reportTypeID = null
                this.serverParams.date = null
                this.loadItems();
                this.loadItemsAirline();
            },
            async getTotalTouristVisitBar() {
                axios.post('Airport/GetTotalTouristVisitInAirportAsync')
                    .then(response => {

                        this.totalTouristVisitBarList = response.data;
                    })
                    .catch(err => {
                        console.log(err)
                    })
                console.log('yaa', this.totalTouristVisitBarList)
                let counta = this.totalTouristVisitBarList.length;
                if (counta == 0 || counta == 'undefined') {
                    this.totalTouristVisitBar = [];
                } else {
                    let formatedData = this.getChartData(this.totalTouristVisitBarList);
                    this.totalTouristVisitBar = formatedData;
                }
            },
            onResponse(data) {
                console.log("ok")
                if (data) {
                    (this.dialogDelete = false), (this.dialogCreate = false);
                    this.dialogEdit = false;
                    this.callbackResponse = data;
                    this.loadItemsAirline();
                } else {
                    this.dialogDelete = false
                }
            },
            onResponseDelete(data) {
                if (data) {
                    this.$emit("formResponseRoomDelete", data);
                    this.dialogDelete = false;
                    this.loadItems();
                } else {
                    this.dialogDelete = false;
                }
            },


            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedData = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            searchInput() {
                this.loadItemsAirline();

            },

            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
                this.loadItemsAirline();
            },

            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage });
                this.loadItems();
                this.loadItemsAirline();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field
                        }
                    ]
                });
                this.loadItems();
                this.loadItemsAirline();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
                this.loadItemsAirline();
            },
            getChartData(data) {
                console.log(data);
                let arr = [];
                arr[0] = Object.keys(data[0]);
                for (let i = 1; i < data.length + 1; i++) {
                    arr[i] = Object.values(data[i - 1]);
                }
                return arr;
            },
            async filterData() {
            },

            loadItemsAirline() {
                this.$nextTick(() => {
                    this.isLoading = true
                    let param = {

                        ReportTypeID: this.serverParams.reportTypeID != null && this.serverParams.reportTypeID != undefined ? parseInt(this.serverParams.reportTypeID) : 0,
                        Date: this.serverParams.date,
                    };

                    axios.post('Airport/GetOccupiedSeatsInAirlineAsync', param)
                        .then(response => {

                            this.totalTouristVisitBarList = response.data;
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    console.log('yaa', this.totalTouristVisitBarList)
                    let counta = this.totalTouristVisitBarList.length;

                    if (counta == 0 || counta == 'undefined') {
                        this.totalTouristVisitBar = [];
                    } else {
                        let formatedData = this.getChartData(this.totalTouristVisitBarList);
                        this.totalTouristVisitBar = formatedData;
                    }
                });
            },



            // load items is what brings back the rows from server
            //async loadItems() {
            //    console.log(this.tokenData);
            //    // eslint-disable-next-line no-console
            //    let param = {

            //        PageSize: this.serverParamsRoom.perPage,
            //        PageNo: this.serverParamsRoom.page,
            //        OrderType: this.serverParamsRoom.sort[0].type,
            //        OrderBy: this.serverParamsRoom.sort[0].field
            //    };
            //    try {
            //        axios.post("Airport/GetAirportListAsync", param).then(response => {
            //            this.roomRows = response.data.data;
            //            this.totalRecords = response.data.totalCount
            //        });


            //        // this.totalRecords = response.data.totalCount;
            //    } catch (e) {
            //        if (e.response.data.State == "AccessTokenExpired") {
            //            this.apiResponse(e);
            //            this.loadItems();
            //        }
            //    }
            //    return;
            //}
        }
    };
</script>

<style lang="scss" scoped>
    .v-item-group {
        background: #d2e3ff73;
    }

    .v-expansion-panel-header {
        background: #d2e3ff73;
    }

    button {
        z-index: 1;
    }
</style>